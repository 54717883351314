
:root {
    --main-blue: #0B1F32;  
    --main-yellow: #E7B628; 
    --main-gray: #f0f0f0;   
    --main-dark-gray: #4f4f4f; 
    --main-white: #ffffff;   
  }

/* Estilos gerais (telas grandes) */
.menu-toggle {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 40px;
    height: 30px;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    background-color: transparent; /* Ajuste para evitar sobreposição */
}
.bar {
    width: 35px;
    height: 4px;
    background-color: black;
    transition: background-color 0.3s ease;
    border-radius: 2px; /* Adiciona um efeito arredondado */
}

.menu-toggle:hover .bar {
    background-color: var(--main-dark-gray); /* Cor de destaque ao passar o cursor */
}
  
  /* Responsivo: até 768px */
  @media (max-width: 768px) {
    .page-container {
        display: block;
        justify-content: center;
        /* background-color: red; */
        width: 100%;
    }
    .content-wrap {
        display: block;
        justify-content: center;
        width: 100%;       
    }
    .template {
        display: block;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* background-color: teal; */
        width: 100%;  
        height: auto;  
        margin: 0;
        padding: 0;   
    }
      ._header {
          display: flex;
          width: 100%;
          height: 110px;
          justify-content: center;
          align-items: center;   
          padding: 0;        
        }
        ._secLogo {
         display: flex;
         justify-content: center;
         align-items: center;
            
        width: 100%;        
        height: auto;
        margin: 0;
        padding: 0;
      }      
      .logoBlack {       
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
        padding: 10px;        
      }
      .logoBlack img {
        display: block;
        width: 30%; 
        margin: 0;
        padding: 0;
    }  
   
    .bar {
        display: block;
        width: 35px; 
        height: 5px;  
        background-color: black;
        margin: 5px 0;
        transition: background-color 0.3s ease;     
    }  
    .menu-toggle:hover .bar {
        background-color: var(--main-dark-gray); 
    }
      nav {
          display: none; 
      }  
      nav.active {
          display: flex;
          flex-direction: column;
          align-items: center;
      }  
      .sobre-nos-container img {
          max-width: 100%; 
      }  
      ._secContato form,
      .textP {
          max-width: 100%; 
          padding: 10px;
          box-sizing: border-box;
      }  
      ._main {
        width: 100%;
    }      
      ._secHome  {
          display: flex;
          width: 100%;
          height: 220px;            
          align-items: center;         
          padding: 0;
          margin: 0;
          margin-top: -40px;       
        }  
      ._secHome h2 {
          margin: 0;
          font-size: 0.8rem;
          text-align: center;
      }  
      ._secHome p {
        font-size: 0.6rem;
    }
    .button-container {
        width: 70%;
        height: 80px;
        margin: 0;
        padding: 0;
        flex-direction: column;
        gap: 10px;
    }
    .cta-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 75%;
        height: 25px;
        font-size: 12px;
        margin: 0;
    }
    button {
        font-size: 0.5rem !important;
    }
    .video-button {
        margin: 0;
        padding: 0;
    }
    .textP p {
          font-size: 1rem; 
    }
    ._secSobreNos {
        width: 100%;
        height: 300px;       
        margin-top: 10px;
    }
    ._secSobreNos h2 {
        font-size: 1.2rem;
        margin: 5px;
    }
    .textP {
        margin-top: -25px;
        padding: 0;
        margin: 0;
    }
    .textP p {
        font-size: 0.7em;
        margin: 0;
    }
    ._secMissao {
        width: 100%;
        height: 310px;
        margin-top: 15px;
    }
    ._secMissao h2 {
        font-size: 1.2rem;
        margin: 5px;
    }
    ._secVisao {
        margin-top: 15px;
    }
    ._secVisao h2 {
        font-size: 1.2rem;
        margin: 5px;
    }
    ._secValores {      
        margin-top: 15px;
    }    
    ._secValores h2 {
        font-size: 1.2rem;
        margin: 5px;
    }
    ._secValores ul {
        display: flex;
        flex-direction: column;
        width: 70%;
        height: 70px;
    }
    ._secValores ul li {
        font-size: 0.8rem;
        margin: 0;
    }
    ._secValores ul li::before {
        content: '✔';
        font-size: 10px;
        color: #E7B628;
        margin-right: 10px;
    } 
    ._secServicos h2 {
        font-size: 1.2rem;
        margin: 5px;
    }
    ._secContato {
        margin-top: 15px;
        padding: 0px 5px;
    }
    ._secContato h2 {
        font-size: 1.2rem;
        margin: 5px;
    }
    ._secContatoBox {
        width: 100%;
        height: 0 auto;
    }
    ._secContatoBox h1 {
        font-size: 1rem;
        margin-top: -15px;        
    }
    form {
        padding: 0;      
    }
    form article{
        margin: 0 !important;      
    }
    article label {
        font-size: 0.7rem !important;
    }
    article input {
        height: 25px !important;
    }
    article button {
        margin: 0 !important;
    }
    ._secPortfolio {       
        margin-top: 15px;       
    }
    ._secPortfolio h2 {
        font-size: 1.2rem;
        margin: 5px;
    }
    .portfolio-list {
        gap: 12px;
    }
    .portfolio-item {
        padding: 0;
    }
    .portfolio-item h3 {
        font-size: 0.8rem;
        margin: 0;
    } 
    .portfolio-item p {
        font-size: 0.7rem;
        margin: 5px;
    }
    .portfolio-item p:nth-child(3) {
        font-size: 0.5rem;
    }
    ._secTestimonials h2 {
        font-size: 1.2rem;
        margin: 5px;
    }
    .testimonials-list {
        display: flex;
        margin: 0;
        padding: 0;
        gap: 0;
    }
    ._textClient p {    
        display: flex;  
        align-items: center;
        justify-content: center;
        font-size: 0.3em !important;             
    }   
    .testimonials-list {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 5px;
    }
    .testimonial-item {
        width: 100%;
        margin: 0;
        padding: 0;
        margin-left: 5px;
        margin-right: 5px;
    }     
    .text-description {             
        margin: 0;
        padding: 0;
    }
    .text-description p {
        display: flex;
        font-size: 0.4em;
        text-align: justify;
        margin: 0;
        padding: 10px;
    }   
    .testimonial-item p::before {
        content: "“";
        font-size: 1.8em;
        color: #ff7f50;
      }
      .testimonial-item p::after {
        content: "”";
        font-size: 1.8em;
        color: #ff7f50;
    }    
   .testimonial-item p span {
    display: block;
    margin-top: 15px;
    font-weight: bold;
    color: #333;
  }
    .Footer {
        margin: 0;
    }
    .footer-section {
        background-color: violet;
        margin: 0;
        padding: 0;
    }
    .Footer-contact {
        margin: 0;
        padding: 0;
    }
    .Footer-contact h4 {
        font-size: 1.2rem;
        margin: 5px;
    }
    .Footer-contact p {
        font-size: 0.4em;
        margin: 0;
    }
    .Footer-links {
        margin: 0;
        padding: 0;
    }
    .Footer-links h4 {
        font-size: 1.2rem;
        margin: 5px;
    }
    .Footer-links ul{
        margin: 0;
        padding: 0;
    }
    .Footer-links ul li {
        margin: 0;
        padding: 0;
        font-size: 0.4em;
    }
    .Footer-links ul li a {
        font-size: 1em;
    }
    .Footer-social {
        margin: 0;
        padding: 0;
    }
    .Footer-social h4 {
        font-size: 1.2rem;
        margin: 5px;
    }
    .Footer-social a {
        font-size: 0.4em;
    }
    .Footer-newsletter {
        margin: 0;
        padding: 0;
    }
    .Footer-newsletter h4 {
        font-size: 1.2rem;
        margin: 5px;
    }
    article input {
        display: flex;
        align-items: center;
    }
    article input::placeholder {
        font-size: 0.6rem !important;
    }    
    .Footer-copyright p {
        font-size: 0.5em;
        margin: 0;
    }
  }
 

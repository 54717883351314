
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; 
}
.modal-content {
  background-color: #fff;
  padding: 2rem;
  border-radius: 10px; 
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3); 
  max-width: 500px;
  text-align: center;
  font-family: 'Arial', sans-serif;
}
.modal-content h2 {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 1rem;
}
.modal-content p {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 2rem;
}
.modal-content button {
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}


/* Estilos para o contêiner principal do Blog */
.blogPage-container {
  font-family: Arial, sans-serif;
  color: #333;
  margin: 0;
  padding: 0;
  background-color: #f9f9f9;
  padding: 20px;
}

/* Estilos para o cabeçalho */
.blogPage-header {
  text-align: center;
  padding: 20px;
  background-color: #282c34;
  color: #fff;
}

.blogPage-header h1 {
  margin: 0;
  font-size: 36px;
}

.blogPage-header p {
  font-size: 18px;
  margin-top: 10px;
}

/* Estilos para os artigos/posts */
.blogPage-post {
  background-color: #fff;
  margin: 20px 0;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.blogPage-post h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.blogPage-post p {
  font-size: 16px;
  color: #555;
}

/* Estilo dos botões */
.blogPage-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.blogPage-button:hover {
  background-color: #0056b3;
}

/* Estilos da modal */
.blogPage-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.blogPage-modalContent {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 600px;
  width: 100%;
  text-align: center;
  position: relative;
}

.blogPage-close {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 24px;
  color: #333;
  cursor: pointer;
}

/* Estilos para o rodapé */
.blogPage-footer {
  text-align: center;
  padding: 20px;
  background-color: #282c34;
  color: #fff;
  margin-top: 40px;
}

.blogPage-footer p {
  margin: 0;
  font-size: 14px;
}
/* Estilo para o botão de voltar */
.blogPage-backButton {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 20px;
  display: inline-block;
  transition: background-color 0.3s ease;
}

.blogPage-backButton:hover {
  background-color: #0056b3;
}

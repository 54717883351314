
.modal-overlayProjectAll {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease; 
}
.modal-contentProjectAll {

  background-color: white;
  padding: 30px;
  border-radius: 15px;  
  width: 90%;
  padding: 30px;
  border-radius: 15px; 
  max-width: 600px;
  max-height: 80%; 
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);   
  animation: slideIn 0.3s ease; 
  overflow-y: auto; 
  animation: slideIn 0.4s ease;  
}
.close-buttonProjectAll{   
  display: flex;    
  font-size: 24px;
  margin-left: 90%;
  cursor: pointer;
  color: #333;
  background: none;
  border: none;
  font-weight: bold;
  z-index: 1002; 
  transition: color 0.2s;
  border-radius: 4px;
}
.close-button:hover { 
  color: #ff4c4c; 
  transition: color 0.2s;  
}
h1 {
  font-size: 28px;
  margin-bottom: 15px;
  color: #333;
}
.modal-content p {
  font-size: 16px;
  line-height: 1.7;
  color: #555;
  text-align: justify; 
}
.modal-content p {
  font-size: 18px; 
  line-height: 1.8; 
  color: #333; 
  text-align: justify; 
  margin-bottom: 20px; 
  padding: 10px;
}
ul { 
  margin-left: 20px;
  list-style-type: disc;
}
li {
 
  margin-bottom: 10px;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes slideIn {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}


.quiz-container {
  max-width: 800px;
  margin: 0 auto; 
  background-color: #f7f9fc;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); 
  font-family: 'Arial', sans-serif;
}
.quiz-container h1 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 1rem;
  text-align: center; 
}
.quiz-container p {
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 2rem;
  text-align: center; 
}
form {
  display: flex;
  flex-direction: column;
}
.section {
  margin-bottom: 2rem;
}
.section h2 {
  font-size: 1.8rem;
  color: #4CAF50;
  margin-bottom: 1rem;
}
.question {
  margin-bottom: 1.5rem;
}
.question label {
  font-size: 1.1rem;
  color: #333;
  margin-bottom: 0.5rem;
  display: block;
}
.question select,
.question input[type="email"] {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease;
}
.question select:focus,
.question input[type="email"]:focus {
  border-color: #4CAF50;
}
button[type="submit"] {
  background-color: #E7B628;
  color:#0b1f32;
  border: none;
  padding: 12px 20px;
  font-size: 1.2rem;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 2rem;
  transition: background-color 0.3s ease;
}
button[type="submit"]:hover {
  background-color:  #f6d163;
}
@media (max-width: 768px) {
  .quiz-container {
    padding: 1.5rem;
  }
  .quiz-container h1 {
    font-size: 2rem;
  }
  .section h2 {
    font-size: 1.5rem;
  }
  button[type="submit"] {
    font-size: 1rem;
  }
}

.whatsapp-button {
  position: fixed;
  right: 20px;
  bottom: 20px;
  background-color: #25d366; 
  color: white;
  padding: 10px 20px;
  border-radius: 50px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  z-index: 1000;
  text-decoration: none;
  transition: transform 0.3s ease;
}

.whatsapp-button img {
  margin-right: 10px;
  width: 30px;
  filter: brightness(0) invert(1); /* Garante que o ícone fique branco sobre fundo colorido */
}

.whatsapp-button:hover {
  transform: scale(1.1);
  background-color: #128c7e;
}


@media (max-width: 768px) {
  .whatsapp-button {
    display: flex;
    width: 25%;
    height: 20px;
    font-size: 0.45rem;
    gap: 2px;
    padding: 0;
  }
  .whatsapp-button img {
    width: 15px;
    padding: 0;
    margin: 5px;

  }
  ._pageWhats {    
    margin: 0;
    padding: 0;
  }

}

.contact-form {
  max-width: 600px;
  margin: 40px auto;
  padding: 25px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}
.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 15px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-family: 'Roboto', sans-serif;
  font-size: 1em;
  box-sizing: border-box;
}
.contact-form button { 
  color: white;
  padding: 15px 30px;
  font-size: 1.1em;
  font-family: 'Poppins', sans-serif;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}
.contact-form button:hover {
    transform: translateY(-3px);
}
@media (max-width: 768px) {
  .contact-form {
    padding: 15px;
  }
  ._pageWhats {
    top: 500px;
    right: 10px;
    padding: 8px;
  }
}

/* Estilos para o modal */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.modal-content {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  width: 80%;
  max-width: 600px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}
.modal-content h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333;
}
.modal-content p, .modal-content ul {
  font-size: 1.2rem;
  color: #555;
}
.modal-content ul {
  list-style-type: none;
  padding: 0;
}
.modal-content ul li {
  margin: 10px 0;
}
.close-button {
 position: absolute;
  top: 160px;
  left: 71%;
  cursor: pointer;
  color: #333;
  transition: color 0.3s ease;
}
.close-button:hover {
  color: #ff0000;
}
